<template>
<span class="text-center mx-auto">
    <v-btn
        v-if="classname == 'cta'"
        elevation="1"
        :href="href"
        :target="target"
        style="color:#fff;border:1px solid var(--color-primary);background:var(--color-primary);"
        dark
    >
     {{ text }}
    </v-btn>
    <v-btn
        v-else
        elevation="1"
        :href="href"
        :target="target"
        style="color:var(--color-primary); border:1px solid var(--color-primary);background:transparent;"
        dark
    >
     {{ text }}
    </v-btn>
</span>
</template>

<script>
export default {
    props: ['href', 'target','text','classname'],
}
</script>

<style>

</style>
<script>
// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from "vue/dist/vue.esm";
import ButtonTheFork from '@/components/ButtonTheFork.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import PhoneCard from '@/components/PhoneCard.vue'
export default {
	props: {
		html: {
			type: String,
			default: "",
		},
    },
    components: {
		buttonthefork:ButtonTheFork,
		buttonprimary:ButtonPrimary,
		phonecard:PhoneCard
    },
	data() {
		return { templateRender: undefined };
	}, 
	watch: {
		html() {
			this.updateRender();
		},
	},
	created() {
		this.updateRender();
	},
	methods: {
		updateRender() {
			const compiled = VueWithCompiler.compile(`<div class="mx-0" style="width:100%;">${this.html}</div>`);
			this.templateRender = compiled.render;
			this.$options.staticRenderFns = [];
			for (const staticRenderFunction of compiled.staticRenderFns) {
				this.$options.staticRenderFns.push(staticRenderFunction);
			}
		},
	},
	render() {
		return this.templateRender();
	},
};
</script>
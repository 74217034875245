<template >
  <v-dialog
    id="TheForkDialog"
    v-model="TheFork"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="#4b5c44"
      >
        <v-btn
          icon
          dark
          @click="toggleTheFork"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('title.dialogTheFork')}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text >
        <div align="center"><iframe :src="`https://bookings.zenchef.com/results?rid=${id}&fullscreen=1&lang=${NoISOLocale}`" style="width: 100%; min-height:100vh; border:none; scrolling:yes;"></iframe></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: ['id', 'show'],
    data: () => ({
      TheFork: false,
      v0: true,
    }),
    watch: {
      // call again the method if the route changes
      'show': function(val) {
        this.TheFork = val
      }
    },
    computed: {
      NoISOLocale() {
        return (this.$i18n.locale == 'fr') ? 'fr' : 'en'
      }
    },
    methods: {
      toggleTheFork(){
        
        this.$emit("toggle",!this.TheFork)
      }
    }
    
    
  }
</script>
<template>
<v-col
    :cols="col"
    class="pt-1 px-1 pb-1"
>
    <v-card
    @click="trigger"
    :color="color"
    dark
    >
    <div class="d-flex flex-no-wrap justify-space-between">
        <div>
            <v-card-title
                class="headline"
                v-text="title"
            ></v-card-title>

            <v-card-subtitle class="pt-2" v-text="phonenumber"></v-card-subtitle>

            <v-card-actions v-show="false">
                <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                ref="phonecardButton"
                :href="`tel:${phonenumber}`"
                >
                Contacter
                </v-btn>
            </v-card-actions>
        </div>

        <v-avatar v-if="col > 11 && icon"
        class="ma-3"
        size="60"
        tile
        >
        <v-icon v-if="col > 11" color="white"
            x-large>
            {{icon}}
        </v-icon>
        
        </v-avatar>
    </div>
    </v-card>
</v-col>
</template>

<script>
export default {
    props: ['col', 'color', 'title', 'phonenumber', 'icon'],
    methods: {
        trigger(){
            this.$refs.phonecardButton.$el.click()
        }
    }
}
</script>

<style>

</style>
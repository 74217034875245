<template>
<span class="text-center mx-auto">
  <v-btn 
    color="var(--color-primary)"
    @click.stop="show = true;"
    dark
    class="moduleTheFork v-btn v-bg-primary elevation-1 v-btn--flat v-btn--text  v-size--default" 
    :data-id="id">
    {{ $t(`btns.${title}`) }}
  </v-btn>
  <DialogTheFork :id="id" :show="show" @toggle="toggleDialog"/>
</span>
</template>

<script>
import DialogTheFork from '@/components/DialogTheFork.vue'

export default {
    props: ['title', 'id'],
    data(){
        return {
            show : false
        }
    },
    components: {
        DialogTheFork
    },
    methods: {
        toggleDialog(val){
            this.show = val
        }
    }
}
</script>

<style>

</style>